<template>
  <div class="ele-body">
    <a-card :bordered="false">
      <a-form
        ref="form"
        :model="form"
        :rules="rules"
        :label-col="{ md: { span: 5 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 19 }, sm: { span: 24 } }"
      >
        <a-form-item
          label="标题:"
          :labelCol="{ span: 2, offset: 0 }"
          name="articleName"
        >
          <a-input
            v-model:value="form.articleTitle"
            placeholder="请输入标题"
            allow-clear
          />
        </a-form-item>
        <a-form-item
          label="类别:"
          :labelCol="{ span: 2, offset: 0 }"
          name="categoryId"
        >
          <a-select
            v-model:value="form.categoryId"
            placeholder="请选择类别"
            allow-clear
          >
            <a-select-option
              v-for="item in categoryList"
              :key="item.categoryId"
              :value="item.categoryId"
            >
              {{ item.categoryName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="作者"
          :labelCol="{ span: 2, offset: 0 }"
          name="author"
        >
          <a-input
            v-model:value="form.author"
            placeholder="请输入作者"
            allow-clear
          />
        </a-form-item>
        <a-form-item
          label="文章封面图:"
          :labelCol="{ span: 2, offset: 0 }"
          name="sortNumber"
        >
          <a-upload
            v-model:file-list="fileList"
            list-type="picture-card"
            class="avatar-uploader"
            :showUploadList="false"
            :customRequest="doUpload"
          >
            <img v-if="imageUrl" :src="imageUrl" alt="封面图" height="100" />
            <div v-else>
              <loading-outlined v-if="loading2"></loading-outlined>
              <plus-outlined v-else></plus-outlined>
              <div class="ant-upload-text">封面图</div>
            </div>
          </a-upload>
        </a-form-item>
        <a-form-item
          label="内容"
          :labelCol="{ span: 2, offset: 0 }"
          name="articleContent"
        >
          <tinymce-editor
            v-model:value="form.articleContent"
            :init="{ height: 525 }"
          />
        </a-form-item>
        <a-form-item
          label="排序:"
          :labelCol="{ span: 2, offset: 0 }"
          name="sortNumber"
        >
          <a-input-number v-model:value="form.sortNumber" :min="0" :step="1" />
          <span class="span">数字越小越靠前</span>
        </a-form-item>
        <a-form-item
          label="虚拟阅读量:"
          :labelCol="{ span: 2, offset: 0 }"
          name="virtualViews"
        >
          <a-input-number
            v-model:value="form.virtualViews"
            :min="0"
            :step="1"
          />
          <span class="span">显示的阅读量 = 实际阅读量 + 虚拟阅读量 </span>
        </a-form-item>
        <a-form-item
          label=" "
          :colon="false"
          :labelCol="{ span: 2, offset: 0 }"
          name="virtualViews"
        >
          <a-button type="primary" @click="save"> 提交 </a-button>
        </a-form-item>
      </a-form>
    </a-card>
  </div>
</template>

<script>
import TinymceEditor from '@/components/TinymceEditor'
import { Modal } from 'ant-design-vue'
import * as articleApi from '@/api/cms/article'
import * as categoryApi from '@/api/cms/category'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue'
import setting from '@/config/setting'
export default {
  name: 'ExtensionEditor',
  components: {
    TinymceEditor,
    PlusOutlined,
    LoadingOutlined
  },
  data() {
    return {
      value: '',
      form: {},
      rules: [],
      uploadUrl: setting.uploadUrl,
      fileList: [],
      loading2: false,
      imageUrl: '',
      categoryList: [],
      isUpdate: false
    }
  },
  created() {
    this.queryCategoryList()
    this.queryArticle()
  },
  methods: {
    /* 上传 */
    doUpload({ file }) {
      const formData = new FormData()
      formData.append('file', file)
      const hide = this.$message.loading('上传中..', 0)
      this.$http
        .post(this.uploadUrl, formData)
        .then((res) => {
          hide()
          if (res.data.code === 0) {
            this.imageUrl = res.data.location
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
      return false
    },
    save() {
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          this.form.imagePath = this.imageUrl
          articleApi
            .save(this.form, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
                if (!this.isUpdate) {
                  this.form = {}
                }
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {})
    },
    /* 获取编辑器纯文本内容 */
    showText() {
      Modal.info({
        maskClosable: true,
        content: this.$util.htmlToText(this.value)
      })
    },
    queryCategoryList() {
      categoryApi
        .all()
        .then((res) => {
          if (res.code === 0) {
            this.categoryList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    queryArticle() {
      const query = this.$route.query
      if (query.id > 0) {
        const that = this
        that.isUpdate = true
        articleApi
          .getById(query.id)
          .then((res) => {
            if (res.code === 0) {
              that.form = Object.assign({}, this.data, res.data)
              that.imageUrl = res.data.imagePath
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((e) => {
            this.$message.error(e.message)
          })
      }
    }
  }
}
</script>

<style scoped>
.span {
  color: grey;
  padding-left: 10px;
}
</style>
